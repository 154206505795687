body {
  margin: 0;
  padding: 0;
  font-family: "PT-Sans", "Roboto", "Montserrat", Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}
